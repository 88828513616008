<template>
  <div>
    <section>
      <TippCard
        hintID="Verfügbarkeit_Archiv_Abfragen_1_V1"
        text="Im Archiv können vergangene Abfragen mit allen Details und Rückmeldungen eingesehen werden. Abfragen können dabei auf einen bestimmten Zeitraum begrenzt werden."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-archive" title="Vergangene Abfragen">
              </Subheader>
              <div class="mt-7">
                <v-card :loading="loading">
                  <v-card-text>
                    <v-container class="mb-n10">
                      <v-form
                        @submit.prevent="startQuery"
                        v-model="formIsValid"
                        ref="form"
                      >
                        <v-row>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.start"
                              type="date"
                              label="Startdatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :disabled="loading"
                              clearable
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.end"
                              type="date"
                              label="Enddatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required]"
                              required
                              :disabled="loading"
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-select
                              v-model="query.status"
                              :items="statusValues"
                              label="Abfragestatus"
                              outlined
                              multiple
                              required
                              item-text="status"
                              item-value="value"
                              :disabled="loading"
                              prepend-inner-icon="mdi-list-status"
                              hide-details="auto"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  :color="statusColor(data.item.value)"
                                  :class="[
                                    'mr-1',
                                    statusTextClass(data.item.value),
                                    'text--darken-4',
                                  ]"
                                  small
                                  @click="toggle"
                                  ><v-icon left small>{{
                                    data.item.icon
                                  }}</v-icon
                                  >{{ data.item.status }}</v-chip
                                >
                              </template>
                            </v-select></v-col
                          >
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="success"
                      :disabled="loading"
                      @click="startQuery()"
                      class="mr-5"
                      >Abfrage starten</v-btn
                    >
                  </v-card-actions>
                </v-card>
                <!-- <v-expansion-panels class="mt-6">
                  <v-expansion-panel disabled>
                    <v-expansion-panel-header>
                      <template v-slot:default="{}">
                        <v-row no-gutters>
                          <v-col cols="4">
                            Weitere Filteroptionen<v-chip
                              small
                              label
                              class="ml-2"
                              >Demnächst verfügbar</v-chip
                            ></v-col
                          >
                        </v-row>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content> -->
                <!-- <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            v-model="query.types"
                            :items="requestTypes"
                            label="Abfrageart"
                            outlined
                            required
                            multiple
                            item-text="title"
                            item-value="meta.id"
                            :disabled="loading"
                            prepend-inner-icon="mdi-format-list-bulleted-type"
                            hide-details="auto"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2" small>
                                <span>{{ item.title }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="grey--text text-caption"
                              >
                                (+{{ query.types.length - 2 }} weitere)
                              </span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row> -->
                <!-- </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels> -->
              </div>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="archiveMatched"
                  item-key="meta.id"
                  multi-sort
                  sort-by="timeSlots.start.timestamp"
                  :sort-desc="true"
                  :group-by.sync="groupBy"
                  :group-desc.sync="groupDesc"
                  :footer-props="footerProps"
                  :items-per-page="25"
                  class="elevation-2"
                  no-data-text="Keine Abfragen in Abfragezeitraum vorhanden"
                  max-height="800px auto"
                  @click:row="routeDetails"
                >
                  <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th :colspan="headers.length">
                      <v-icon @click="toggle" class="mr-2"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      {{ stripPrefix(items[0].groupBy) }}
                      ({{ getCountOfRequestsInMonth(items[0].groupBy) }})
                    </th>
                  </template>
                  <template v-slot:[`item.type.title`]="{ item }">
                    <v-chip
                      small
                      :color="`${getMaterialColor(item.type.color)} lighten-4`"
                      :class="`${getMaterialColor(
                        item.type.color
                      )}--text text--darken-4 font-weight-medium`"
                    >
                      {{ item.type.title }}
                    </v-chip>
                  </template>
                  <template
                    v-slot:[`item.timeSlots.start.timestamp`]="{ item }"
                  >
                    {{ item.timeSlots.start.formattedTimestamp }}
                  </template>
                  <template v-slot:[`item.timeSlots.end.timestamp`]="{ item }">
                    {{ item.timeSlots.end.formattedTimestamp }}
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      :color="statusColor(item.status)"
                      :class="[
                        'font-weight-medium',
                        statusTextClass(item.status),
                        'text--darken-4',
                      ]"
                      small
                    >
                      <v-icon left small>{{ statusIcon(item.status) }}</v-icon
                      >{{ statusText(item.status) }}
                    </v-chip>
                  </template>
                  <template v-slot:no-data>
                    <div
                      style="
                        height: 300px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <p class="subtitle-1">
                        Keine Abfragen in diesem Zeitraum vorhanden.
                      </p>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { AVAILABILITY } from "@/store/modules.js";
import {
  GET_ARCHIVED_REQUESTS,
  // GET_EVENT_TYPES,
} from "@/store/action-types.js";

export default {
  name: "availability-archive-tab-requests",
  components: {
    Subheader,
    TippCard,
    SupportTools,
  },
  data() {
    return {
      search: "",
      sortDesc: true,
      sortBy: "title",
      groupBy: "groupBy",
      groupDesc: true,

      // Table Display Settings
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },

      query: {
        range: {
          start: null,
          end: null,
        },
        status: ["postponed", "canceled", "finished"],
        types: [],
        groups: [],
      },
      formIsValid: false,
      loading: true,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      headers: [
        { text: "Titel", value: "title" },
        { text: "Abfrageart", value: "type.title" },
        { text: "Startzeitpunkt", value: "timeSlots.start.timestamp" },
        {
          text: "Endzeitpunkt",
          value: "timeSlots.end.timestamp",
          sortable: false,
        },
        { text: "Ort", value: "location.name" },
        { text: "Status", value: "status" },
      ],

      statusValues: [
        {
          icon: "mdi-calendar",
          value: "planned",
          status: "Geplant",
        },
        {
          icon: "mdi-calendar-alert",
          value: "postponed",
          status: "Verschoben",
        },
        {
          icon: "mdi-calendar-remove",
          value: "canceled",
          status: "Abgesagt",
        },
        {
          icon: "mdi-calendar-check",
          value: "finished",
          status: "Abgeschlossen",
        },
      ],
    };
  },
  computed: {
    userGroups() {
      const userGroups = this.$store.state.admin.userGroups.map((obj) => ({
        title: obj.title,
        id: obj.meta.id,
      }));

      userGroups.unshift({
        title: "Alle",
        id: "all",
      });
      return userGroups;
    },
    requestTypes() {
      return this.$store.state.availability.requestTypes;
    },
    archive() {
      return this.$store.state.availability.archivedRequests;
    },
    archiveMatched() {
      const b = this.archive.map((obj) => ({
        ...obj,
        groupBy: this.groupByDateText(obj.timeSlots.start.timestamp.toDate()),
      }));
      return b;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "archiveMatched", data: this.archiveMatched },
        { title: "query", data: this.query },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.query.range.end = new Date().toISOString().substr(0, 10);
      // this.$store
      //   .dispatch(`${AVAILABILITY}${GET_REQUEST_TYPES}`, {
      //     organizationId: this.$route.params.organizationId,
      //   })
      //   // .then(() => {
      //   //   return (this.query.types = this.requestTypes.map((obj) => obj.meta.id));
      //   // })
      //   .then(() => {
      //     this.loading = false;
      //   });
      this.loading = false;
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
        case "#F44336":
          return "red";
        case "#E91E63FF":
        case "#E91E63":
          return "pink";
        case "#9C27B0FF":
        case "#9C27B0":
          return "purple";
        case "#673AB7FF":
        case "#673AB7":
          return "deep-purple";
        case "#3F51B5FF":
        case "#3F51B5":
          return "indigo";
        case "#2196F3FF":
        case "#2196F3":
          return "blue";
        case "#03A9F4FF":
        case "#03A9F4":
          return "light-blue";
        case "#00BCD4FF":
        case "#00BCD4":
          return "cyan";
        case "#009688FF":
        case "#009688":
          return "teal";
        case "#4CAF50FF":
        case "#4CAF50":
          return "green";
        case "#8BC34AFF":
        case "#8BC34A":
          return "light-green";
        case "#CDDC39FF":
        case "#CDDC39":
          return "lime";
        case "#FFEB3BFF":
        case "#FFEB3B":
          return "yellow";
        case "#FFC107FF":
        case "#FFC107":
          return "amber";
        case "#FF9800FF":
        case "#FF9800":
          return "orange";
        case "#FF5722FF":
        case "#FF5722":
          return "deep-orange";
        case "#795548FF":
        case "#795548":
          return "brown";
        case "#607D8BFF":
        case "#607D8B":
          return "blue-grey";
        case "#9E9E9EFF":
        case "#9E9E9E":
          return "grey";
        default:
          return "grey";
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    startQuery() {
      this.validate();
      if (this.formIsValid) {
        if (this.query.range.start == "") {
          this.query.range.start = null;
        }
        this.$store.dispatch(`${AVAILABILITY}${GET_ARCHIVED_REQUESTS}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            range: {
              start: this.query.range.start,
              end: this.query.range.end,
            },
            // types: this.query.types,
            status: this.query.status,
          },
        });
        console.log(this.archive);
      } else {
        alert("Fehler: Es ist ein Enddatum für die Abfrage erforderlich.");
      }
    },
    getCountOfRequestsInMonth(monthId) {
      return this.archiveMatched.filter(
        (request) => request.groupBy === monthId
      ).length;
    },
    statusIcon(status) {
      switch (status) {
        case "planned":
          return "mdi-calendar";
        case "postponed":
          return "mdi-calendar-alert";
        case "canceled":
          return "mdi-calendar-remove";
        case "finished":
          return "mdi-calendar-check";
        case "deletion started":
          return "mdi-delete-outline";
        default:
          break;
      }
    },
    statusText(status) {
      switch (status) {
        case "planned":
          return "Geplant";
        case "postponed":
          return "Verschoben";
        case "canceled":
          return "Abgesagt";
        case "finished":
          return "Abgeschlossen";
        case "deletion started":
          return "Löschung gestartet";
        default:
          break;
      }
    },
    statusColor(status) {
      switch (status) {
        case "planned":
          return "blue lighten-4";
        case "postponed":
          return "orange lighten-4";
        case "canceled":
          return "red lighten-4";
        case "finished":
          return "green lighten-4";
        case "deletion started":
          return "red lighten-4";
        default:
          break;
      }
    },
    statusTextClass(status) {
      switch (status) {
        case "planned":
          return "blue--text";
        case "postponed":
          return "orange--text";
        case "canceled":
          return "red--text";
        case "finished":
          return "green--text";
        case "deletion started":
          return "red--text";
        default:
          break;
      }
    },
    groupByDateText(date) {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const monthLong = date.toLocaleString("de-DE", { month: "long" });
      return year + "-" + month + "_" + monthLong + " " + year;
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    routeDetails(item) {
      this.$router.push({
        name: "scheduling-requests-details-tab-general",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
  },
};
</script>

<style></style>
