<template>
  <div>
    <NavigationBar
      titel="Archiv"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "scheduling-archive",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `archive/events`,
      tabs: [
        { id: 1, name: "Veranstaltungen", route: `events` },
        { id: 2, name: "Abfragen", route: `requests` },
      ],
    };
  },
};
</script>
